// import {Howl, Howler} from 'howler';
function musicPlayer () {
    
    let tracknumber = 0
    let playingId = null
    let tracklist = [
        "sound_01", 
        "sound_02", 
        "sound_03",
        "sound_04",
        "sound_05",
        "sound_06", 
        "sound_07", 
        "sound_08",
        "sound_09",
        "sound_10",
        "sound_11",
        "sound_12",        
    ]
    let fadeOut = 1.2;
    let playBtns = Array.from(document.getElementsByClassName('player__playlist__track'))
    let lyricsDesktop = Array.from(document.getElementById('lyrics-desktop').getElementsByClassName('lyric'));
    let lyricsMobile = Array.from(document.getElementsByClassName('player__playlist__lyric'));
    let credits = Array.from(document.getElementsByClassName('credit'));
    let creditsMobile = Array.from(document.getElementsByClassName('creditMobile'));
    let lyricsMobileArr = [];
    lyricsMobile.forEach((el, index) => {
        el.getElementsByClassName('lyric')[0].innerHTML = lyricsDesktop[index].innerHTML;
        el.getElementsByClassName('lyric')[0].className = lyricsDesktop[index].classList
        lyricsMobileArr.push(el.getElementsByClassName('lyric')[0])
    })
    $('.global-play').hide()
    // $('#lyrics-desktop').addClass('hidden');
    $('.play').hide()
    Howler.autoUnlock = false;
    let players = {
        sound_01: new Howl({
            src: ['trax/01_AAA_Powerline.mp3'],
            onend: function() { 
                endHandler(0) 
            },
            autoSuspend: false
        }),
        sound_02: new Howl({
            src: ['trax/02_Peroxide.mp3'],
            onend: function() { 
                endHandler(1) 
            },
            autoSuspend: false 
        }),
        sound_03: new Howl({
            src: ['trax/03_Fragile.mp3'],
            onend: function() { 
                endHandler(2) 
            },
            autoSuspend: false 
        }),
        sound_04: new Howl({
            src: ['trax/04_Bliss_Fields.mp3'],
            onend: function() { 
                endHandler(3) 
            } ,
            autoSuspend: false
        }),
        sound_05: new Howl({
            src: ['trax/05_Fruit_Bleed_Juice.mp3'],
            onend: function() { 
                endHandler(4) 
            } ,
            autoSuspend: false
        }),
        sound_06: new Howl({
            src: ['trax/06_Cc.mp3'],
            onend: function() { 
                endHandler(5) 
            } ,
            autoSuspend: false
        }),
        sound_07: new Howl({
            src: ['trax/07_Calcium.mp3'],
            onend: function() { 
                endHandler(6) 
            } ,
            autoSuspend: false
        }),
        sound_08: new Howl({
            src: ['trax/08_Sugar_&_Diesel.mp3'],
            onend: function() { 
                endHandler(7) 
            } ,
            autoSuspend: false
        }),
        sound_09: new Howl({
            src: ['trax/09_Dont_Ask.mp3'],
            onend: function() { 
                endHandler(7) 
            } ,
            autoSuspend: false
        }),
        sound_10: new Howl({
            src: ['trax/10_Security!.mp3'],
            onend: function() { 
                endHandler(7) 
            } ,
            autoSuspend: false
        }),
        sound_11: new Howl({
            src: ['trax/11_Time.mp3'],
            onend: function() { 
                endHandler(7) 
            } ,
            autoSuspend: false
        }),
        sound_12: new Howl({
            src: ['trax/12_Blue_Eyes.mp3'],
            onend: function() { 
                endHandler(7) 
            } ,
            autoSuspend: false
        })
    }
 
    function endHandler(id) {
        $('.play').show()
        $('.pause').hide()
    }
    

    let isPlaying = false 
    function playHandler(id) {
        
        if(!$('.bg-holder__image').hasClass('animate')) {
            setTimeout(function() {
                $('.bg-holder__image').addClass('animate');
            }, 1000)
        }
        $('.global-play').show();
        tracknumber = id
        playingId = tracklist[id]
        

        if(isPlaying){

        }else {
            players[tracklist[tracknumber]].play();
            setTimeout(function() {
                isPlaying = true;
            }, 100)
        }
        let staggerGroup = Array.from(lyricsDesktop[tracknumber].getElementsByTagName('p'));
        let staggerGroupMobile = Array.from(lyricsMobileArr[tracknumber].getElementsByTagName('p'));
        players[playingId].fade(0, 1, 1000)
        TweenMax.to([lyricsDesktop, credits, creditsMobile], 0.2 ,{force3D:true,autoAlpha:0, ease: Power1.easeIn})
        TweenMax.to([lyricsDesktop[tracknumber], lyricsMobileArr[tracknumber]], 0.1 ,{force3D:true, delay: .1, autoAlpha:1})
        TweenMax.to([lyricsDesktop[tracknumber], lyricsMobileArr[tracknumber]], 0.1 ,{force3D:true, delay: .1, autoAlpha:1})
        TweenMax.to(credits[tracknumber], .4 ,{delay: .2, autoAlpha:1,force3D:true, ease: Power4.easeInOut })
        TweenMax.to(creditsMobile[tracknumber], .4 ,{delay: .4, autoAlpha:1,force3D:true, ease: Power4.easeInOut })
        if($('#lyrics-desktop').hasClass('hidden')) {
            TweenMax.to('#lyrics-desktop', .5, {delay:.1,force3D:true, x:0 , autoAlpha:1, ease: Power1.easeOut})
            TweenMax.to('.cred-desk', .4, {delay:.2, force3D:true , autoAlpha:1, ease: Power1.easeOut})
        }
        TweenMax.from(staggerGroup, .5 ,{delay:.1,x:-100, autoAlpha:0,force3D:true, ease: "Power4.in" }) 
        TweenMax.from(staggerGroupMobile, .1 ,{autoAlpha:0,force3D:true, ease: Power4.easeInOut })        
    }

    function stopHandler(playingId, id) {
        
        $('.bg-holder__image').removeClass('animate');

        if(playingId) {
            players[playingId].fade(1, 0, 1000)
            isPlaying = false
            setTimeout(function() {
                players[playingId].stop()
            }, 1000)
        }
    }
    function pauseHandler(id) {
        // if(isPlaying && $('#lyrics-desktop').hasClass('hidden')) {
            
        // }

        if ($('#lyrics-desktop').hasClass('hidden')) {
            // players[playingId].fade(0, 1, 1000)
            // players[tracklist[tracknumber]].play();
            console.log('asdfasdfasdf');
            $('.global-play').show();
           
            TweenMax.to('#lyrics-desktop', .5, {force3D:true, x:0 , autoAlpha:1, ease: Power1.easeOut, onComplete: function() {
                $('#lyrics-desktop').removeClass('hidden');
            }})
            TweenMax.to('.cred-desk', .5, {force3D:true , autoAlpha:1, ease: Power1.easeOut})
        }else {
            
            $('.global-play').hide();
            TweenMax.to('#lyrics-desktop', .5, {force3D:true, x:-100 , autoAlpha:0, ease: Power1.easeOut, onComplete: function() {
                $('#lyrics-desktop').addClass('hidden');
            }})
            
            TweenMax.to('.cred-desk', .5, {force3D:true , autoAlpha:0, ease: Power1.easeOut})
        }   
    }

    $('.global-play').on('click', function (e) {
        let id = e.target.dataset.player
        console.log(id);
        tracknumber = id
        playingId = tracklist[id]
        
        if(players[tracklist[id]].playing()) {
            $('.bg-holder__image').addClass('animation-pause');
            $('.global-play').removeClass('playing')
            $('.play').show()
            $('.pause').hide()
            players[playingId].fade(1, 0, 1000)
            setTimeout(function() {
                players[tracklist[id]].pause();
            }, 1000)
        }else {
            $('.global-play').addClass('playing')
            $('.bg-holder__image').removeClass('animation-pause');   
            
            $('.pause').show()
            $('.play').hide()
            players[playingId].fade(0, 1, 1000)
            players[tracklist[tracknumber]].play();
        }
        // pauseHandler(e.target.dataset.player);
    })

    playBtns.forEach(function(btn, index) {
        btn.addEventListener('click', function(){
            let $btn = $(btn);    
            let sameTrack =  playingId === tracklist[index]
            
            $('.global-play').attr('data-player', index)
            if(!$('.global-play').hasClass('playing')) {
                $('.global-play').addClass('playing')
            }
           
            if(!sameTrack) {
                stopHandler(playingId,btn.dataset.id)
                playHandler(index)
                if ($('.open').length) {
                    $('.open').removeClass('open')

                    setTimeout(function() {
                        $btn.addClass('open')
                    }, 1000)
                    
                    
                }  else {
                    $btn.addClass('open')
                    
                }    
            }else {
                pauseHandler(index)
                if($btn.hasClass('open')){
                    $btn.removeClass('open')
                    TweenMax.to('.credit-mobile', .4, {force3D:true, autoAlpha:0,  ease: Power4.easeOut})
                } else {
                    $btn.addClass('open')
                    TweenMax.to('.credit-mobile', .4, {force3D:true, autoAlpha:1,  ease: Power4.easeOut})
                }
            }
            
            
            
        })  
    })

}

export default musicPlayer
function animation() {
    const fadeIn = 2
    let lyricsDesktop = Array.from(document.getElementById('lyrics-desktop').getElementsByClassName('lyric'));
    let lyricsMobile = Array.from(document.getElementsByClassName('player__playlist__lyric'));
    let credits = Array.from(document.getElementsByClassName('credit'));
    let creditsMobile = Array.from(document.getElementsByClassName('creditMobile'));
    let lyricsMobileArr = [];
    lyricsMobile.forEach((el, index) => {
        el.getElementsByClassName('lyric')[0].innerHTML = lyricsDesktop[index].innerHTML;
        el.getElementsByClassName('lyric')[0].className = lyricsDesktop[index].classList
        lyricsMobileArr.push(el.getElementsByClassName('lyric')[0])
    })
    
    TweenMax.to(['.player', '.external', '.press', '.shop', '.shop-cd'], .1, {force3D:true, x:-100,  autoAlpha:0, ease: Power1.easeOut})
    TweenMax.to('.App__view-cart', .1, {force3D:true, x:100,  autoAlpha:0, ease: Power1.easeOut})
    TweenMax.to(['.shop-holder', '.shop-cd-holder'], .1, {autoAlpha:0})
    $('#internal').on('click',function(){
        $('.player-col').removeClass('wide')
        TweenMax.to('.credit-mobile', .5, {force3D:true, autoAlpha:1,  ease: Power1.easeOut})
        TweenMax.to('.App__view-cart', .5, {force3D:true, x:100,  autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.player', .5, {delay:.5,force3D:true, x:0 ,autoAlpha:1, ease: Power1.easeOut})
        TweenMax.to('#lyrics-desktop', .5, {delay:.7,force3D:true, x:0,autoAlpha:1 ,  ease: Power1.easeOut})
        TweenMax.to('.cred-desk', .5, {delay:.7,force3D:true, autoAlpha:1,  ease: Power1.easeOut})
        TweenMax.to('.external', .5, {force3D:true,x:-100,  autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.press', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.shop', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut, onComplete: function() {
            TweenMax.to('.shop-holder', .1, {autoAlpha:0})
            $('.App__view-cart--open').click();
        }})
        TweenMax.to('.shop-cd', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut, onComplete: function() {
            TweenMax.to('.shop-cd-holder', .1, {autoAlpha:0})
            $('.App__view-cart--open').click();
        }})
        $('.product--open').click();
    })
    $('#home').on('click',function(){
        TweenMax.to(['.cred-desk', '.credit-mobile'], .1, {force3D:true, autoAlpha:0,  ease: Power1.easeOut})
        // TweenMax.to(['.player', '#lyrics-desktop', '.cred-desk'], .1, {force3D:true,  autoAlpha:0, ease: Power1.easeOut}) 
        TweenMax.to('.App__view-cart', .5, {force3D:true, x:100,  autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.player', .5, {delay:.2,force3D:true, x:-100 , autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('#lyrics-desktop', .5, {force3D:true, x:-100 , autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.external', .5, {force3D:true,x:-100 , autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.press', .5, {force3D:true,x:-100,  autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.shop', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut, onComplete: function() {
            TweenMax.to('.shop-holder', .1, {autoAlpha:0})
            $('.App__view-cart--open').click();
        }})
        TweenMax.to('.shop-cd', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut, onComplete: function() {
            TweenMax.to('.shop-cd-holder', .1, {autoAlpha:0})
            $('.App__view-cart--open').click();
        }})
        $('.product--open').click();
    })
    $('#external').on('click',function(){
        TweenMax.to('.App__view-cart', .5, {force3D:true, x:100,  autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to(['.cred-desk', '.credit-mobile'], .01, {force3D:true, autoAlpha:0,  ease: Power1.easeOut})
        TweenMax.to('.external', .5, {delay:.6,force3D:true, x:0,autoAlpha:1, ease: Power1.easeOut})
        TweenMax.to('.player', .5, {delay:.2,force3D:true, x:-100 , autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('#lyrics-desktop', .5, {force3D:true, x:-100 , autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.shop', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut, onComplete: function() {
            TweenMax.to('.shop-holder', .1, {autoAlpha:0})
            $('.App__view-cart--open').click();
        }})
        TweenMax.to('.shop-cd', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut, onComplete: function() {
            TweenMax.to('.shop-cd-holder', .1, {autoAlpha:0})
            $('.App__view-cart--open').click();
        }})
        TweenMax.to('.press', .5, {force3D:true,x:-100,  autoAlpha:0, ease: Power1.easeOut})
        $('.product--open').click();
    })
    $('#info').on('click',function(){
        TweenMax.to('.App__view-cart', .5, {force3D:true, x:100,  autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.cred-desk', .1, {force3D:true, autoAlpha:0,  ease: Power1.easeOut})
        TweenMax.to('.press', .5, {force3D:true, x:0 ,delay:.6,autoAlpha:1, ease: Power1.easeOut})
        TweenMax.to('.player', .5, {delay:.2,force3D:true, x:-100 , autoAlpha:0, onComplete: function() {
            $('.player-col').addClass('wide')
        }})
        TweenMax.to('#lyrics-desktop', .5, {force3D:true, x:-100 , autoAlpha:0, ease: Power1.easeOut})
        $('.product--open').click();
        TweenMax.to('.shop', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut, onComplete: function() {
            TweenMax.to('.shop-holder', .1, {autoAlpha:0})
            $('.App__view-cart--open').click();
        }})
        TweenMax.to('.shop-cd', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut, onComplete: function() {
            TweenMax.to('.shop-cd-holder', .1, {autoAlpha:0})
            $('.App__view-cart--open').click();
        }})
        TweenMax.to('.external', .5, { force3D:true,x:-100,  autoAlpha:0, ease: Power1.easeOut}) 
            
    })
    $('#shop').on('click',function(){
        $('.product--open').click();
        $('.App__view-cart--open').click();
        $('.App__header').css('display', 'flex')
        TweenMax.to('.App__view-cart', .5, {force3D:true, x:100,  autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.shop-holder', .01, {force3D:true, autoAlpha:1,  ease: Power1.easeOut})
        TweenMax.to('.cred-desk', .1, {force3D:true, autoAlpha:0,  ease: Power1.easeOut})
        TweenMax.to('.shop', .5, {force3D:true, x:0 ,delay:.6,autoAlpha:1, ease: Power1.easeOut ,onComplete: function() {
            
        }})
        TweenMax.to('.App__view-cart', 0.5, {delay:.7, force3D:true,autoAlpha:1, x:0, ease: Power1.easeOut})
        TweenMax.to('.player', .5, {delay:.2,force3D:true, x:-100 , autoAlpha:0, ease: Power1.easeOut,onComplete: function() {
            $('.player-col').addClass('wide')
        }})
        TweenMax.to('#lyrics-desktop', .5, {force3D:true, x:-100 , autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.shop-cd', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut, onComplete: function() {
            TweenMax.to('.shop-cd-holder', .1, {autoAlpha:0})
            $('.App__view-cart--open').click();
        }})
        TweenMax.to('.press', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.external', .5, { force3D:true,x:-100,  autoAlpha:0, ease: Power1.easeOut}) 
        
    })
    $('#physical').on('click',function(){
        $('.product--open').click();
        $('.App__view-cart--open').click();
        $('.App__header').css('display', 'flex')
        TweenMax.to('.App__view-cart', .5, {force3D:true, x:100,  autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.shop-cd-holder', .01, {force3D:true, autoAlpha:1,  ease: Power1.easeOut})
        TweenMax.to('.cred-desk', .1, {force3D:true, autoAlpha:0,  ease: Power1.easeOut})
        TweenMax.to('.shop-cd', .5, {force3D:true, x:0 ,delay:.6,autoAlpha:1, ease: Power1.easeOut ,onComplete: function() {
            
        }})
        TweenMax.to('.App__view-cart', 0.5, {delay:.7, force3D:true,autoAlpha:1, x:0, ease: Power1.easeOut})
        TweenMax.to('.player', .5, {delay:.2,force3D:true, x:-100 , autoAlpha:0, ease: Power1.easeOut,onComplete: function() {
            $('.player-col').addClass('wide')
        }})
        TweenMax.to('#lyrics-desktop', .5, {force3D:true, x:-100 , autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.shop', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut, onComplete: function() {
            TweenMax.to('.shop-holder', .1, {autoAlpha:0})
            $('.App__view-cart--open').click();
        }})
        TweenMax.to('.press', .5, {force3D:true, x:-100, autoAlpha:0, ease: Power1.easeOut})
        TweenMax.to('.external', .5, { force3D:true,x:-100,  autoAlpha:0, ease: Power1.easeOut}) 
        
    })
}

export default animation;
import musicPlayer from './musicPlayer';
import animation from './animation';

document.addEventListener('DOMContentLoaded', () => {
  console.log('initialized');
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
  musicPlayer();
  animation();
});
